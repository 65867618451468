@tailwind base;
@tailwind components;
@tailwind utilities;

.bi-embedded{
  height: 100%;
  background-color: white;
}

html:not(.dark) .helper-text-black, html:not(.dark) .helper-text-black * {
  /* Styles for .content when 'dark' class is NOT on the root */
  color: #000!important;
}
html:not(.dark) .helper-alert-black, html:not(.dark) .helper-alert-black * {
  /* Styles for .content when 'dark' class is NOT on the root */
  background-color: #fff!important;
  color: #000!important;
}

html:not(.dark) .helper-toast-success {
background-color: #f6ffed!important;
border-color: #b7eb8f!important;
}
html:not(.dark) .helper-toast-info {
  background-color: #e6f7ff!important;
  border-color: #91d5ff!important;
}
html:not(.dark) .helper-toast-warning {
  background-color: #fffbe6!important;
  border-color: #ffe58f!important;
}
html:not(.dark) .helper-toast-error {
  background-color: #fff1f0!important;
  border-color: #ffa39e!important;
}

html:not(.dark) .helper-footer-logo-block {
  display: block!important;
}

html:not(.dark) .helper-footer-logo-hidden {
  display: none!important;
}

html:not(.dark) .helper-input-bg-white {
  background-color: #fff!important;
}

html:not(.dark) .helper-input-bg-slate {
  background-color: rgb(243 244 246)!important;
  border-color: #e2e8f0!important;
}

@layer components {

@media (min-width: 768px){
  .dark .dark\:md\:block {
    display: block;
  }

}
.dark .dark\:block {
  display: block;
}
.dark .dark\:hidden {
  display: none;
}

.dark .dark\:bg-green-200, .dark .dark\:\!bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-green-400, .dark .dark\:\!bg-green-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:text-gray-300, .dark .dark\:\!text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-gray-400, .dark .dark\:\!text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-yellow-400, .dark .dark\:\!text-yellow-400{
  --tw-text-opacity: 1 !important;
  color: rgb(250 204 21 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-red-400, .dark .dark\:\!text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-red-700, .dark .dark\:\!text-red-700{
  --tw-text-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-text-opacity)) !important;
}

.dark .dark\:bg-neutral-800, .dark .dark\:\!bg-neutral-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity)) !important;
}

.dark .dark\:bg-neutral-400, .dark .dark\:\!bg-neutral-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:border-neutral-400, .dark .dark\:\!border-neutral-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}
.dark .dark\:\!text-white{
@apply !text-white
}
.dark .dark\:text-white{
@apply text-white
}
.dark .dark\:placeholder-gray-400, .dark .dark\:\!placeholder-gray-400{
  --tw-placeholder-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity)) !important;
}
.dark .dark\:bg-green-900, .dark .dark\:\!bg-green-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:border-b-green-800, .dark .dark\:\!border-b-green-800{
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}
.dark .dark\:bg-yellow-900, .dark .dark\:\!bg-yellow-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:border-b-yellow-800, .dark .dark\:\!border-b-yellow-800{
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}
.dark .dark\:bg-red-900, .dark .dark\:\!bg-red-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:border-b-red-800, .dark .dark\:\!border-b-red-800{
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}
.dark .dark\:bg-slate-900, .dark .dark\:\!bg-slate-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.dark .dark\:border-b-slate-800, .dark .dark\:\!border-b-slate-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.dark .dark\:border-b-neutral-800, .dark .dark\:\!border-b-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.dark .dark\:text-slate-100, .dark .dark\:\!text-slate-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(241 245 249 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-slate-400, .dark .dark\:\!text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-slate-300, .dark .dark\:\!text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-slate-700, .dark .dark\:\!text-slate-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(51 65 85 / var(--tw-text-opacity)) !important;
}
.dark .dark\:border-b-neutral-700, .dark .dark\:\!border-b-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.dark .dark\:bg-green-500, .dark .dark\:\!bg-green-500 {
  @apply !bg-green-500;
}
.dark .dark\:border-green-500, .dark .dark\:\!border-green-500 {
  @apply !border-green-500;
}
.dark .dark\:bg-green-600, .dark .dark\:\!bg-green-600 {
  @apply !bg-green-600;
}
.dark .dark\:border-green-600, .dark .dark\:\!border-green-600 {
  @apply !border-green-600;
}
.dark .dark\:bg-yellow-500, .dark .dark\:\!bg-yellow-500 {
  @apply !bg-yellow-500 ;
}
.dark .dark\:border-yellow-500, .dark .dark\:\!border-yellow-500 {
  @apply !border-yellow-500;
}
.dark .dark\:bg-yellow-600, .dark .dark\:\!bg-yellow-600 {
  @apply !bg-yellow-600 ;
}
.dark .dark\:border-yellow-600, .dark .dark\:\!border-yellow-600 {
  @apply !border-yellow-600;
}
.dark .dark\:bg-red-500, .dark .dark\:\!bg-red-500 {
  @apply !bg-red-500;
}
.dark .dark\:border-red-500, .dark .dark\:\!border-red-500 {
  @apply !border-red-500;
}
.dark .dark\:bg-red-600, .dark .dark\:\!bg-red-600 {
  @apply !bg-red-600;
}
.dark .dark\:border-red-600, .dark .dark\:\!border-red-600 {
  @apply !border-red-600;
}
.dark .dark\:bg-gray-600, .dark .dark\:\!bg-gray-600 {
  @apply !bg-gray-600;
}
.dark .dark\:border-gray-600, .dark .dark\:\!border-gray-600 {
  @apply !border-gray-600;
}
.dark .dark\:bg-gray-950, .dark .dark\:\!bg-gray-950 {
  @apply !bg-gray-950;
}
.dark .dark\:border-gray-950, .dark .dark\:\!border-gray-950 {
  @apply !border-gray-950;
}
.dark .dark\:bg-gray-300, .dark .dark\:\!bg-gray-300 {
  @apply !bg-gray-300;
}
.dark .dark\:border-gray-300, .dark .dark\:\!border-gray-300 {
  @apply !border-gray-300;
}
.dark .dark\:bg-slate-600, .dark .dark\:\!bg-slate-600 {
  @apply !bg-slate-600;
}
.dark .dark\:border-slate-800, .dark .dark\:\!border-slate-800 {
  @apply !border-slate-800;
}
.dark .dark\:bg-slate-500, .dark .dark\:\!bg-slate-500 {
  @apply !bg-slate-500;
}
.dark .dark\:border-slate-500, .dark .dark\:\!border-slate-500 {
  @apply !border-slate-500;
}
.dark .dark\:text-black, .dark .dark\:\!text-black {
  @apply !text-black;
}
  
}
